import Cookies from 'js-cookie';

const getCookieItem = (key: string) => {
  return Cookies.get(key);
};

const setCookieItem = (key: string, value: any, ttl: number) => {
  const ttlInMinutes = (1 / 24 / 60) * ttl;
  Cookies.set(key, value, { expires: ttlInMinutes });
};

const removeCookieItem = (key: string) => {
  Cookies.remove(key);
};

const setRedirectUrlToCookie = () => {
  setCookieItem(
    COOKIE_ITEMS.redirect,
    window.location.pathname + window.location.search,
    300
  );
};

const COOKIE_ITEMS = {
  counter: 'counter',
  redirect: 'redirect',
  isSignIn: 'is_signin',
  wantedSync: 'wanted_sync',
  popupDisabled: 'popupdisabled',
  disableNewCompanyPopup: 'disablenewcompanypopup',
  disableNewFeaturePopup: 'disablenewfeaturepopup',
  disableOneIdPopup: 'disableOneIdPopup',
  endpoint: 'endpoint',
  register: 'register',
};

export {
  getCookieItem,
  setCookieItem,
  removeCookieItem,
  COOKIE_ITEMS,
  setRedirectUrlToCookie,
};
