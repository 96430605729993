import * as Sentry from '@sentry/nextjs';

import { errorLogger, responseLogger } from './logger';

import axios from 'axios';

export const getAxiosInstance = (baseUrl: string) => {
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      try {
        return config;
      } catch (err) {
        throw Error(err);
      }
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axiosInstance.interceptors.response.use(
    (config) => {
      const { method = '', baseURL, url } = config.config;
      const { data, status } = config;
      if (process.env.NEXT_PUBLIC_STAGE === 'development') {
        if (data?.success === false || data?.ok === false || status >= 400) {
          errorLogger({
            method,
            body: '',
            status,
            url: baseURL || '' + url || '',
            message: data,
          });
        } else {
          responseLogger({
            method,
            status,
            response: data,
            url: url || '',
          });
        }
      }
      return config;
    },
    (err) => {
      const { message, response, config } = err;
      const { data, method, url } = config || {};
      if (process.env.NEXT_PUBLIC_STAGE === 'development') {
        errorLogger({
          method,
          body: data,
          status: response?.status || 0,
          url: url,
          message,
        });
      }
      Sentry.setContext('API Response Error', {
        method,
        status: response?.status || 0,
        url,
        message,
      });
      return Promise.reject(err);
    }
  );

  return axiosInstance;
};
