import { COOKIE_ITEMS, getCookieItem } from '@/utils/cookieControl';
import {
  IArchitectureDetail,
  IArchitectureInfo,
  IArchitectureList,
} from '@/types/architecture';
import { IArticle, IPopularArticleList } from '@/types/article';
import {
  ICompanyDetail,
  ICompanyListBasicInfo,
  ICompanyListCard,
} from '@/types/company';
import {
  IContentDetail,
  IProfileData,
  IRelatedContentList,
} from '@/types/discoverys';
import { IJobFilter, IJobPostList } from '@/types/job';
import { INewsDetail, INewsList } from '@/types/news';
import { IQuiz, ITopic } from '@/types/discussions';
import {
  ISearchHints,
  ISearchList,
  ISearchRecommendKeywords,
} from '@/types/search';
import { ITechblogDetail, ITechblogTotalPage } from '@/types/techblog';
import {
  ITechstack,
  ITechstackDetail,
  ITechstackInfo,
  ITechstackList,
} from '@/types/techstack';

import { AxiosRequestConfig } from 'axios';
import { FilterProps } from '@/features/Jobpost/hook/api';
import FormData from 'form-data';
import { IComment } from '@/types/comment';
import { ISchedule } from '@/types/calendar';
import { ITopicList } from '@/types/discussions';
import { IUser } from '@/types/common';
import { convertCamelObjectToSnake } from '@/utils/utils';
import { getAxiosInstance } from '@/config/instance';

const BASEURL = process.env.NEXT_PUBLIC_BASE_URL;

export const ENDPOINT =
  process.env.NEXT_PUBLIC_STAGE === 'production'
    ? process.env.NEXT_PUBLIC_API_ENDPOINT
    : getCookieItem(COOKIE_ITEMS.endpoint) ||
      process.env.NEXT_PUBLIC_API_ENDPOINT;
export const STATIC_ENDPOINT = process.env.NEXT_PUBLIC_STATIC_ENDPOINT;
export const AUTH_ENDPOINT = process.env.NEXT_PUBLIC_AUTH_ENDPOINT;

export const COMPANY_LOGO_ENDPOINT = `${STATIC_ENDPOINT}/company-logo`;
export const FRAMEWORK_LOGO_ENDPOINT = `${STATIC_ENDPOINT}/framework_logo`;

export const WANTED_WD_ENDPOINT = 'https://www.wanted.co.kr/wd';
export const WANTED_STATIC_ENDPOINT = 'https://static.wanted.co.kr/images/wdes';
export const WANTED_STATIC_ENDPOINT_COMPANY = 'https://static.wanted.co.kr';

const axiosInstance = getAxiosInstance(BASEURL || '');

const getAPI = async <T>(
  url: string,
  props: AxiosRequestConfig = {}
): Promise<T> => {
  try {
    const res = await axiosInstance.get(url, props);

    return res?.data;
  } catch (e) {
    console.log(url + ' : ' + e);
    return e;
  }
};

export const API_ENDPOINT = {
  company: {
    getBasicInfo: (keyword: string | null, category: string | null) => {
      const url = new URL(`${ENDPOINT}/company`);
      if (keyword) url.searchParams.set('keyword', keyword);
      else if (category) url.searchParams.set('category', category);
      return getAPI<ICompanyListBasicInfo>(url.toString());
    },
    getCompanyList: (
      after: string | null,
      keyword: string | null,
      category: string | null
    ) => {
      const url = new URL(`${ENDPOINT}/companies`);
      if (after) url.searchParams.set('after', after);
      if (keyword) url.searchParams.set('keyword', keyword);
      if (category) url.searchParams.set('category', category);
      return getAPI<{ companies: ICompanyListCard[] }>(url.toString());
    },
    getCompanyDetail: (companyId: string) =>
      getAPI<ICompanyDetail>(`${ENDPOINT}/company/detail/${companyId}`),
    getAllCompanyId: () =>
      getAPI<{ company_ids: number[] }>(`${ENDPOINT}/company/ids`),
  },

  techstack: {
    getBasicInfo: (keyword: string | null, category: string | null) => {
      const url = new URL(`${ENDPOINT}/techstack`);
      if (keyword) url.searchParams.set('keyword', keyword);
      else if (category) url.searchParams.set('category', category);
      return getAPI<ITechstackInfo>(url.toString());
    },
    getTechstackList: (
      after: string,
      keyword: string | null,
      category: string | null
    ) => {
      const url = new URL(`${ENDPOINT}/techstacks`);
      if (after) url.searchParams.set('after', after);
      if (keyword) url.searchParams.set('keyword', keyword);
      else if (category) url.searchParams.set('category', category);
      return getAPI<ITechstackList>(url.toString());
    },
    getCategoryList: () =>
      axiosInstance.get(`${ENDPOINT}/techstack/category-list`),
    getTechstackDetail: (techstackId: string) =>
      getAPI<ITechstack>(`${ENDPOINT}/techstack/detail/${techstackId}`),
    getAllTechstackList: () =>
      getAPI<ITechstackDetail[]>(`${ENDPOINT}/techstack/all-techstack-list`),
  },

  statistics: {
    getBasicInfo: () => axiosInstance.get(`${ENDPOINT}/statistics`),
    getStatistics: (category) =>
      getAPI(encodeURI(`${ENDPOINT}/statistics/detail/${category}`)),
  },

  calendar: {
    getSchedule: (year, month) =>
      axiosInstance.get(`${ENDPOINT}/calendar?year=${year}&month=${month}`),
    getUpcomingSchedule: () =>
      getAPI<ISchedule[]>(`${ENDPOINT}/calendar/upcoming`),
    addScheduleRequest: (data) =>
      axiosInstance.post(`${ENDPOINT}/calendar/add-request`, data),
  },

  techblog: {
    getBasicInfo:
      ({
        keyword,
        tag,
        companyId,
      }: {
        keyword?: string;
        tag?: string;
        companyId?: number;
      }) =>
      () => {
        const url = new URL(`${ENDPOINT}/techblogs/total-count`);
        if (keyword && !tag) url.searchParams.set('keyword', keyword);
        else if (tag && !keyword) url.searchParams.set('tag', tag);
        else if (companyId) url.searchParams.set('company_id', companyId + '');
        return getAPI<ITechblogTotalPage>(url.toString());
      },
    viewcount: (articleId) => {
      if (process.env.NEXT_PUBLIC_STAGE !== 'local') {
        return axiosInstance.post(`${ENDPOINT}/techblogs/viewcount`, {
          article_id: articleId,
        });
      }
    },
    getArticleList:
      ({
        page = 1,
        keyword = '',
        tag = '',
        companyId,
      }: {
        page: number;
        keyword?: string;
        tag?: string;
        companyId?: number;
      }) =>
      () => {
        const url = new URL(`${ENDPOINT}/techblogs`);
        url.searchParams.set('page', page + '');
        if (keyword && !tag) url.searchParams.set('keyword', keyword);
        else if (tag && !keyword) url.searchParams.set('tag', tag);
        else if (companyId) url.searchParams.set('company_id', companyId + '');
        return getAPI<ITechblogDetail[]>(url.toString());
      },
    getPopularArticle: () =>
      getAPI<IPopularArticleList<IArticle>>(
        `${ENDPOINT}/techblogs/popular_article`
      ),
    getCategoryList: () => getAPI<any>(`${ENDPOINT}/techblogs/categorylist`),
    getRecommendedTagList: () =>
      getAPI<any>(`${ENDPOINT}/techblogs/recommended-tags`),
    getFavoriteIDList: () =>
      getAPI<number[]>(`${ENDPOINT}/techblogs/favorite/idlist`),
    getFavoriteItemList: (page) =>
      axiosInstance.get(`${ENDPOINT}/techblogs/favorite/itemlist?page=${page}`),
    addFavoriteItem: (id) => {
      const data = { article_id: id };
      return axiosInstance.post(`${ENDPOINT}/techblogs/favorite/item`, data);
    },
    deleteFavoriteItem: (id) => {
      const data = { article_id: id };
      return axiosInstance.delete(`${ENDPOINT}/techblogs/favorite/item`, {
        data: data,
      });
    },
    deleteAllFavoriteItem: () =>
      axiosInstance.delete(`${ENDPOINT}/techblogs/favorite/allitem`),
    evaluate: (id, like) =>
      axiosInstance.put(`${ENDPOINT}/techblogs/${id}/like`, {
        like,
      }),
  },

  recruit: {
    getJobPostList: () => axiosInstance.get(`${ENDPOINT}/recruit/list`),
    getJobPostByCompanyId: (companyId) =>
      axiosInstance.get(`${ENDPOINT}/recruit/company/${companyId}`),
    getJobPostByTechstackId: (techstackId) =>
      axiosInstance.get(`${ENDPOINT}/recruit/techstack/${techstackId}`),
    getRecommendedCompanyJobPostByUser: (companyId) =>
      axiosInstance.get(
        `${ENDPOINT}/recruit/recommendation/company?company_id=${companyId}`
      ),
    getRecommendedTechstackJobPostByUser: (techstackId) =>
      axiosInstance.get(
        `${ENDPOINT}/recruit/recommendation/techstack?techstack_id=${techstackId}`
      ),
    getRecommendedJobpost: (after) => {
      const url = new URL(`${ENDPOINT}/recruits/recommends`);
      if (after) url.searchParams.set('after', after);
      return getAPI<IJobPostList>(url.toString());
    },
  },

  recruits: {
    getFilters: (campaign) => {
      const url = new URL(`${ENDPOINT}/recruits/filters`);
      if (campaign) url.searchParams.set('campaign', campaign);
      return getAPI<IJobFilter>(url.toString());
    },
    getWantedJobPostList: ({
      category,
      techstackIds,
      career,
      locations,
      sizes,
      leaveRates,
      salaries,
      after,
      campaign,
      companyId,
    }: FilterProps) => {
      const url = new URL(`${ENDPOINT}/recruits`);

      if (category) url.searchParams.set('category', category);
      if (techstackIds && Array.from(techstackIds).length > 0)
        url.searchParams.set('techstack_ids', [...techstackIds].toString());
      if (career) url.searchParams.set('careers', career);
      if (locations && locations.length > 0)
        url.searchParams.set('locations', locations.toString());
      if (sizes && sizes.length > 0)
        url.searchParams.set('sizes', sizes.toString());
      if (leaveRates && leaveRates.length > 0)
        url.searchParams.set('leave_rates', leaveRates.toString());
      if (salaries && salaries.length > 0)
        url.searchParams.set('salaries', salaries.toString());
      if (after) url.searchParams.set('after', after);
      if (campaign) url.searchParams.set('campaign', campaign);
      if (companyId) url.searchParams.set('company_id', companyId + '');

      url.searchParams.set('limit', '24');

      return getAPI<IJobPostList>(url.toString());
    },
    getBookmarkList: () =>
      getAPI<IJobPostList>(`${ENDPOINT}/recruits/bookmarks?limit=100`),
    addBookmark: (id) => {
      const body = {
        type: 'jobpost',
        type_id: id,
      };
      return axiosInstance.post(`${ENDPOINT}/bookmarks/add`, body);
    },
    deleteBookmark: (id) => {
      const body = {
        type: 'jobpost',
        type_id: id,
      };
      return axiosInstance.post(`${ENDPOINT}/bookmarks/delete`, body);
    },
    deleteAllBookmarks: () => {
      const body = { type: 'jobpost' };
      return axiosInstance.post(`${ENDPOINT}/bookmarks/delete_all`, body);
    },
  },

  form: {
    getTechstackCategoryList: () =>
      axiosInstance.get(`${ENDPOINT}/form/addcompany/category`),
    addCompanyRequest: (data) =>
      axiosInstance.post(`${ENDPOINT}/form/addcompany/submit`, data),
    addScheduleRequest: (data) =>
      axiosInstance.post(`${ENDPOINT}/form/addschedule/submit`, data),
    requestChange: (data) =>
      axiosInstance.post(`${ENDPOINT}/form/editcompany/submit`, data),
    subscription: (data) =>
      axiosInstance.post(`${ENDPOINT}/form/subscription`, data),
    requestVerificationCode: (email) =>
      axiosInstance.post(`${ENDPOINT}/form/verification/request-code`, {
        email: email,
      }),
    checkVerificationCode: (email, verificationCode) => {
      const data = { email: email, verificationCode: verificationCode };
      return axiosInstance.post(
        `${ENDPOINT}/form/verification/verify-code`,
        data
      );
    },
    sendCodenaryPositionInterest: (data) =>
      axiosInstance.post(`${ENDPOINT}/form/codenary-position-interest`, data),
    getPresigned: (mime_type) =>
      axiosInstance.get(`${ENDPOINT}/form/presigned_url`, {
        params: {
          mime_type,
        },
      }),
  },

  board: {
    getPostList: () => {
      // if (keyword && tag) return axiosInstance.get(`${ENDPOINT}/techblogs/list?page=${page}`)
      // if (keyword) return axiosInstance.get(`${ENDPOINT}/techblogs/list?page=${page}&keyword=${keyword}`)
      // if (tag) return axiosInstance.get(`${ENDPOINT}/techblogs/list?page=${page}&tag=${tag}`)
      // if (companyId) return axiosInstance.get(`${ENDPOINT}/techblogs/list?page=${page}&company_id=${companyId}`)
      // else return axiosInstance.get(`${ENDPOINT}/techblogs/list?page=${page}`)
    },
    getCategoryInfo: () => axiosInstance.get(`${ENDPOINT}/board/category`),
    getPost: (postId) => axiosInstance.get(`${ENDPOINT}/board/post/${postId}`),
    addPost: (data) => axiosInstance.post(`${ENDPOINT}/board/post`, data),
    editPost: (postId, data) =>
      axiosInstance.put(`${AUTH_ENDPOINT}/board/post/${postId}`, data),
    deletePost: (data) =>
      axiosInstance.delete(`${ENDPOINT}/board/post`, {
        data: data,
      }),

    getCommentList: () => null,
    addComment: () => null,
    editComment: () => null,
    deleteComment: () => null,
  },

  viewcount: {
    increaseTechblog: (contentId) => {
      if (process.env.NEXT_PUBLIC_STAGE !== 'local') {
        return axiosInstance.post(`${ENDPOINT}/logs/view-content`, {
          type: 'TECHBLOG',
          content_id: contentId,
        });
      }
    },
    increaseArchitecture: (contentId) => {
      if (process.env.NEXT_PUBLIC_STAGE !== 'local') {
        return axiosInstance.post(`${ENDPOINT}/logs/view-content`, {
          type: 'ARCHITECTURE',
          content_id: contentId,
        });
      }
    },
    increaseDiscussion: (contentId) => {
      if (process.env.NEXT_PUBLIC_STAGE !== 'local') {
        return axiosInstance.post(`${ENDPOINT}/logs/view-content`, {
          type: 'DISCUSSION',
          content_id: contentId,
        });
      }
    },
    increaseCompany: (contentId) => {
      if (process.env.NEXT_PUBLIC_STAGE !== 'local') {
        return axiosInstance.post(`${ENDPOINT}/logs/view-content`, {
          type: 'COMPANY',
          content_id: contentId,
        });
      }
    },
    increaseTechstack: (contentId) => {
      if (process.env.NEXT_PUBLIC_STAGE !== 'local') {
        return axiosInstance.post(`${ENDPOINT}/logs/view-content`, {
          type: 'TECHSTACK',
          content_id: contentId,
        });
      }
    },
    increaseJobpost: (contentId) => {
      if (process.env.NEXT_PUBLIC_STAGE !== 'local') {
        return axiosInstance.post(`${ENDPOINT}/logs/view-content`, {
          type: 'JOPOST',
          content_id: contentId,
        });
      }
    },
  },

  insight: {
    getInsightList: (last_key) =>
      last_key
        ? axiosInstance.get(`${ENDPOINT}/insight/list?last_key=${last_key}`)
        : axiosInstance.get(`${ENDPOINT}/insight/list`),
    getInsight: (id, signed_in) =>
      signed_in
        ? axiosInstance.get(`${ENDPOINT}/insight/detail/${id}`)
        : axiosInstance.get(`${ENDPOINT}/insight/detail/${id}`),
  },

  architecture: {
    getBasicInfo: () =>
      getAPI<IArchitectureInfo>(`${ENDPOINT}/architecture/basicinfo`),
    getArchitectureList: (
      category: string,
      techstackIds: string,
      cursor: string,
      company: string
    ) => {
      const url = new URL(`${ENDPOINT}/architecture/list/v2`);
      url.searchParams.set('limit', 12 + '');
      if (category) url.searchParams.set('category', category);
      if (techstackIds) url.searchParams.set('techstack_ids', techstackIds);
      if (cursor) url.searchParams.set('after', cursor);
      if (company) url.searchParams.set('company_id', company);
      return getAPI<IArchitectureList>(url.toString());
    },
    getArchitectureDetail: (id) =>
      getAPI<IArchitectureDetail>(`${ENDPOINT}/architecture/detail/${id}`),
    getPopularArchitecture: () =>
      getAPI<IPopularArticleList<IArchitectureDetail>>(
        `${ENDPOINT}/architecture/popular_article`
      ),
    addComment: (architectureId, parentId, text) => {
      const data = {
        architecture_id: architectureId,
        parent_id: parentId,
        text: text,
      };
      return axiosInstance.post(`${ENDPOINT}/architecture/comment/add`, data);
    },
    editComment: (commentId, text) => {
      const data = { comment_id: commentId, text: text };
      return axiosInstance.post(`${ENDPOINT}/architecture/comment/edit`, data);
    },
    deleteComment: (commentId) => {
      const data = { comment_id: commentId };
      return axiosInstance.post(
        `${ENDPOINT}/architecture/comment/delete`,
        data
      );
    },
    getFavoriteArchitectureID: () =>
      getAPI<number[]>(`${ENDPOINT}/architecture/favorite/item/ids`),
    getFavoriteArchitectureItemList: () =>
      getAPI(`${ENDPOINT}/architecture/favorite/item/list`),
    checkFavoriteArchitectureItem: (architectureId) =>
      axiosInstance.get(
        `${ENDPOINT}/architecture/favorite/item/check?id=${architectureId}`
      ),
    addFavoriteArchitectureItem: (architectureId) => {
      const data = { architecture_id: architectureId };
      return axiosInstance.post(
        `${ENDPOINT}/architecture/favorite/item/add`,
        data
      );
    },
    deleteFavoriteArchitectureItem: (architectureId) => {
      const data = { architecture_id: architectureId };
      return axiosInstance.post(
        `${ENDPOINT}/architecture/favorite/item/delete`,
        data
      );
    },
    deleteAllFavoriteArchitectureItem: () =>
      axiosInstance.post(`${ENDPOINT}/architecture/favorite/item/deleteall`),
  },

  auth: {
    getBasicInfo: () => axiosInstance.get(`${AUTH_ENDPOINT}/basic-info`),
    loginUser: (data) => axiosInstance.post(`${AUTH_ENDPOINT}/login`, data),
    logoutUser: () => axiosInstance.post(`${AUTH_ENDPOINT}/logout`),
    registerUser: (data) =>
      axiosInstance.post(`${AUTH_ENDPOINT}/register-user`, data),
    getUser: () => getAPI<IUser>(`${AUTH_ENDPOINT}/get-user`),
    checkUser: (username) =>
      axiosInstance.get(`${AUTH_ENDPOINT}/check-user?username=${username}`),
    updateUser: (data) =>
      axiosInstance.put(`${AUTH_ENDPOINT}/update-user`, data),
    updateRegisterUser: (data) =>
      axiosInstance.post(`${AUTH_ENDPOINT}/update-user`, data),
    updateUserNewsletter: (data) =>
      axiosInstance.put(`${AUTH_ENDPOINT}/update-user-newsletter`, data),
    cancelUserNewsletter: () =>
      axiosInstance.put(`${AUTH_ENDPOINT}/cancel-user-newsletter`, {}),
    deleteUser: () => axiosInstance.delete(`${AUTH_ENDPOINT}/delete-user`),
    uploadUserProfileImage: (file) => {
      const data = new FormData();
      data.append('image', file);
      return axiosInstance.post(
        `${AUTH_ENDPOINT}/upload-user-profile-img`,
        data
      );
    },
    updateUserProfileImageUrl: (url) =>
      axiosInstance.post(`${AUTH_ENDPOINT}/update-user-profile-img-url`, {
        url,
      }),

    getMyResume: () => axiosInstance.get(`${AUTH_ENDPOINT}/myresume`),
    deleteMyResume: () =>
      axiosInstance.delete(`${AUTH_ENDPOINT}/delete-myresume`),
    uploadMyResumeURL: () => `${AUTH_ENDPOINT}/upload-myresume`,
    updateSuggestionInfo: (data) =>
      axiosInstance.post(`${AUTH_ENDPOINT}/update-suggestion-info`, data),
    getMyTechstack: () => axiosInstance.get(`${AUTH_ENDPOINT}/mytechstack`),
    addMyTechstack: (data) =>
      axiosInstance.post(`${AUTH_ENDPOINT}/add-mytechstack`, data),
    removeMyTechstack: (techstackId) => {
      const data = { techstack_id: techstackId };
      return axiosInstance.post(`${AUTH_ENDPOINT}/remove-mytechstack`, data);
    },
    disableOneID: () => {
      return axiosInstance.get(`${AUTH_ENDPOINT}/no-migration`);
    },
  },

  newsletter: {
    getNewsletter: (uid, user_id, week) => {
      const url = new URL(`${ENDPOINT}/newsletter/list`);
      if (uid) url.searchParams.set('uid', uid);
      if (user_id) url.searchParams.set('user_id', user_id);
      if (week) url.searchParams.set('week', week);
      return getAPI<any>(url + '');
    },
    getManualContent: () =>
      axiosInstance.get(`${ENDPOINT}/newsletter/manual-content/list`),
    requestNewsletter: (data) =>
      axiosInstance.post(`${ENDPOINT}/newsletter/request`, data),
    getNewsList: (week, year) => {
      const url = new URL(`${ENDPOINT}/newsletter/news-list`);
      if (week) url.searchParams.set('week', week);
      if (year) url.searchParams.set('year', year);
      return getAPI<{ items: INewsDetail[] }>(url + '');
    },
  },
  discussion: {
    getTopicDetail: (discussionId, discussion_group_id) => {
      const url = new URL(`${ENDPOINT}/discussions/${discussionId}`);
      if (discussion_group_id)
        url.searchParams.set('discussion_group_id', discussion_group_id);
      return getAPI<{ item: ITopic }>(url + '');
    },
    getTopicList: (after: string) => {
      return after
        ? getAPI<ITopicList>(`${ENDPOINT}/discussions?limit=24&after=${after}`)
        : getAPI<ITopicList>(`${ENDPOINT}/discussions?limit=24`);
    },
    getTopicTodayList: () =>
      getAPI<ITopicList>(`${ENDPOINT}/discussions/today`),
    voteOption: (discussionId, optionId) => {
      const body = { discussion_vote_option_id: optionId };
      return axiosInstance.post(
        `${ENDPOINT}/discussions/${discussionId}/votes`,
        body
      );
    },
    getQuiz: (id: string) =>
      getAPI<IQuiz>(`${ENDPOINT}/discussions/quiz?content_id=${id}`),
    getDiscussionTechstackGroups: () =>
      getAPI<any>(`${ENDPOINT}/discussion_techstack_groups`),
    getDiscussionMySummary: () =>
      getAPI<any>(`${ENDPOINT}/discussion_groups/my-summary`),
    useGetMyUsableTechstacks: () =>
      getAPI<any>(`${ENDPOINT}/discussion_techstack_groups/usable-techstacks`),
    useGetMyDiscussionGroups: (after = '', limit) =>
      getAPI<any>(
        `${ENDPOINT}/discussion_groups?after=${after}&limit=${limit}`
      ),
    requestTechstackGroups: (body) =>
      axiosInstance.post(`${ENDPOINT}/discussion_techstack_groups`, {
        ...body,
      }),
    requestTechstackGroupsGenerate: (id) =>
      axiosInstance.post(
        `${ENDPOINT}/discussion_techstack_groups/${id}/generate`
      ),
    getDiscussionGroupId: (id) =>
      getAPI<any>(`${ENDPOINT}/discussion_groups/${id}`),
    getDiscussionGroupIdByTeam: (id) =>
      getAPI<any>(`${ENDPOINT}/discussion_groups/${id}/team`),
    getDiscussionGroupRankingByTeam: (id) =>
      getAPI<any>(`${ENDPOINT}/discussion_groups/${id}/ranking`),
    getDiscussionTechstackGroup: () =>
      getAPI<any>(`${ENDPOINT}/discussion_techstack_groups/techstack`),
    requestDiscussionGroupId: (id, body) =>
      axiosInstance.post(`${ENDPOINT}/discussion_groups/${id}/submit`, {
        answers: body,
      }),
  },

  github: {
    getAllChartData: (username, type) => {
      const url = new URL(`${ENDPOINT}/githubs/chart`);
      url.searchParams.set('type', type);
      if (username) url.searchParams.set('username', decodeURI(username));
      return axiosInstance.get(url + '');
    },
    getAllSummaryData: (username) => {
      const url = new URL(`${ENDPOINT}/githubs/summary`);
      if (username) url.searchParams.set('username', username);
      return axiosInstance.get(url + '');
    },
    getRepos: () => axiosInstance.get(`${ENDPOINT}/githubs/repos`),
    getSyncStatus: () => axiosInstance.get(`${ENDPOINT}/githubs/sync-status`),
    getGraph: (group, type) => {
      const url = new URL(`${ENDPOINT}/githubs/graph`);
      url.searchParams.set('group', group);
      url.searchParams.set('type', type);
      return axiosInstance.get(url.toString());
    },
    getGroupByRepo: (type) =>
      axiosInstance.get(`${ENDPOINT}/githubs/graph/group-by-repo?type=${type}`),
    syncGithub: () => axiosInstance.post(`${ENDPOINT}/githubs/sync`, {}),
  },

  userProfile: {
    getUserProfile: (username) =>
      axiosInstance.get(`${ENDPOINT}/profiles/${username}`),
    getMyProfile: () => axiosInstance.get(`${ENDPOINT}/profiles/me`),
    getSpeechList: (username = '') => {
      const url = new URL(`${ENDPOINT}/profiles/conferences`);
      if (username) url.searchParams.set('username', username);
      return axiosInstance.get(url.toString());
    },
    updateMyProfile: (profileInfo) => {
      const body = { profile: convertCamelObjectToSnake(profileInfo) };
      return axiosInstance.put(`${ENDPOINT}/profiles`, body);
    },
    getContentsOauthURL: () =>
      axiosInstance.get(`${ENDPOINT}/contents/oauth-url`),
    syncContent: (type, data) => {
      const body = { type: type, data: data };
      return axiosInstance.put(`${ENDPOINT}/contents/user/sync`, body);
    },
    unSyncContent: (type) => {
      const body = { type: type };
      return axiosInstance.put(`${ENDPOINT}/contents/user/sync-delete`, body);
    },
    contentSyncStatus: (type) => {
      return axiosInstance.get(
        `${ENDPOINT}/contents/user/sync-status?type=${type}`
      );
    },
    validateContentSync: (sync, type) => {
      const body = { sync, type };
      return axiosInstance.post(`${ENDPOINT}/contents/user/sync-valid`, body);
    },
    addContent: (data) => {
      const body = {
        type: data.type,
        title: data.title,
        summary: data.summary,
        url: data.url,
        conference_name: data.conference_name,
      };
      return axiosInstance.post(`${ENDPOINT}/contents/add-content`, body);
    },
    deleteContent: (id) => axiosInstance.delete(`${ENDPOINT}/contents/${id}`),
  },

  notification: {
    getNotifications: (limit, page) =>
      axiosInstance.get(
        `${ENDPOINT}/notifications?limit=${limit}&page=${page}`
      ),
    readNotification: (id) =>
      axiosInstance.put(`${ENDPOINT}/notifications/${id}`, {}),
    getRedDot: () => {
      if (getCookieItem('is_signin') === 'true')
        return axiosInstance.get(`${ENDPOINT}/notifications/red-dot`);
    },
    updateRedDot: () =>
      process.env.NEXT_PUBLIC_STAGE === 'production'
        ? axiosInstance.post(`${ENDPOINT}/notifications/red-dot`, {})
        : null,
  },
  wanted: {
    // 원티드와의 연동 API
    getCompanyInfo: (wantedId) =>
      axiosInstance.get(
        `${ENDPOINT}/wanted/company-info?wanted_id=${wantedId}`
      ),
    getCompanyList: (keyword) =>
      axiosInstance.get(`${ENDPOINT}/wanted/company-list?keyword=${keyword}`),
  },

  comment: {
    getCommentList: (type, typeId) =>
      getAPI<{ items: IComment[] }>(
        `${ENDPOINT}/comments/all?type=${type}&type_id=${typeId}`
      ),
    addComment: (type, typeId, parentId, text) => {
      const body = {
        type: type,
        type_id: typeId,
        parent_id: parentId,
        text: text,
      };
      return axiosInstance.post(`${ENDPOINT}/comments`, body);
    },
    deleteComment: (commentId) =>
      axiosInstance.delete(`${ENDPOINT}/comments/${commentId}`),
    editComment: (commentId, text) => {
      const body = { text: text };
      return axiosInstance.put(`${ENDPOINT}/comments/${commentId}`, body);
    },
    updateCommentLikeCount: (id, liked) => {
      const body = { enabled: liked };
      return axiosInstance.put(`${ENDPOINT}/comments/${id}/likes`, body);
    },
  },

  news: {
    getNewsList: (after?: string) => {
      const url = new URL(`${ENDPOINT}/news`);
      if (after) url.searchParams.set('after', after);
      return getAPI<INewsList>(url.toString());
    },
    getNewsDetail: (id: number) =>
      getAPI<INewsDetail>(`${ENDPOINT}/news/${id}`),
    putNewsLike: (id, enabled: boolean) =>
      axiosInstance.put(`${ENDPOINT}/news/${id}/likes`, {
        enabled,
      }),
  },

  search: {
    getSearchResult: (keyword: string, types?: string, after?: string) => {
      const url = new URL(`${ENDPOINT}/searchs`);
      if (types) url.searchParams.set('type', types);
      url.searchParams.set('keyword', keyword);
      if (after) url.searchParams.set('after', after);
      return getAPI<ISearchList>(url.toString());
    },
    getSearchRecommendKeywords: () =>
      getAPI<ISearchRecommendKeywords>(
        `${ENDPOINT}/searchs/recommend-keywords`
      ),
    getHints: (keyword: string) =>
      getAPI<ISearchHints>(`${ENDPOINT}/searchs/hint?keyword=${keyword}`),
  },

  report: {
    getReportReasonTypes: () =>
      axiosInstance.get(`${ENDPOINT}/reports/reason_types`),
    submitReport: (type, typeId, reasonType, reason) => {
      const body = {
        type: type,
        type_id: typeId,
        reason_type: reasonType,
        reason_comment: reason,
      };
      return axiosInstance.post(`${ENDPOINT}/reports`, body);
    },
  },

  activity: {
    getMyCommentList: (pageNum) =>
      axiosInstance.get(`${ENDPOINT}/activities/my-comments?page=${pageNum}`),
    getMyLikedCommentList: (pageNum) =>
      axiosInstance.get(`${ENDPOINT}/activities/my-likes?page=${pageNum}`),
  },

  discovery: {
    getTimelineContent: (type: string[], cursor: string, category: string) => {
      const url = new URL(`${ENDPOINT}/discoverys/timeline`);
      url.searchParams.set('limit', 12 + '');
      url.searchParams.set('type', type + '');
      if (category) url.searchParams.set('category', category);
      if (cursor) url.searchParams.set('after', cursor);
      return getAPI<IRelatedContentList>(url.toString());
    },
    getProfileList: () =>
      getAPI<IProfileData>(`${ENDPOINT}/discoverys/profiles`),
    getRelatedContent: (id: number) =>
      getAPI<IRelatedContentList>(`${ENDPOINT}/discoverys/${id}/related`),
    getUserContent: (cursor = '', username = '') => {
      const url = new URL(`${ENDPOINT}/discoverys`);
      url.searchParams.set('limit', 12 + '');
      if (cursor) url.searchParams.set('after', cursor);
      if (username) url.searchParams.set('username', username);
      return axiosInstance.get(url.toString());
    },
    getContentDetail: (id: number) => {
      return getAPI<IContentDetail>(`${ENDPOINT}/discoverys/${id}`);
    },
    editContent: (id, tags, techstackIds) => {
      const body = { tags: tags, techstack_ids: techstackIds };

      return axiosInstance.put(`${ENDPOINT}/discoverys/${id}`, body);
    },
    deleteContent: (id) => axiosInstance.delete(`${ENDPOINT}/discoverys/${id}`),
    updateContentLikeCount: (id: number, liked: boolean) => {
      const body = { enabled: liked };
      return axiosInstance.put(`${ENDPOINT}/discoverys/${id}/like`, body);
    },
    contentVisible: (id, visible) => {
      const body = { visible: visible };
      return axiosInstance.post(`${ENDPOINT}/discoverys/${id}/visible`, body);
    },
    getBookmarkList: (cursor) => {
      const url = new URL(`${ENDPOINT}/discoverys/bookmarks`);
      url.searchParams.set('limit', 12 + '');
      if (cursor) url.searchParams.set('after', cursor);
      return axiosInstance.get(url.toString());
    },
    addBookmarkItem: (id: number) => {
      const body = { id };
      return axiosInstance.post(`${ENDPOINT}/discoverys/bookmarks`, body);
    },
    deleteBookmarkItem: (id: number) =>
      axiosInstance.delete(`${ENDPOINT}/discoverys/bookmarks/${id}`),
    deleteAllBookmarkItems: () =>
      axiosInstance.delete(`${ENDPOINT}/discoverys/bookmarks`),
  },

  notice: {
    getNotice: (after = '') => {
      const url = new URL(`${ENDPOINT}/notices`);
      url.searchParams.set('limit', 12 + '');
      if (after) url.searchParams.set('after', after);
      return getAPI(url.toString());
    },
  },
};
