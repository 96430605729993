'use client';

import {
  COOKIE_ITEMS,
  getCookieItem,
  setRedirectUrlToCookie,
} from './cookieControl';
import {
  ReadonlyURLSearchParams,
  usePathname,
  useSearchParams,
} from 'next/navigation';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import snakeCase from 'lodash/snakeCase';
import { useMantineColorScheme } from '@mantine/core';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(weekOfYear);

const sleep = (ms) => {
  return new Promise((r) => setTimeout(r, ms));
};

const groupBy = (data, key) => {
  const groupedList = data?.reduce(function (carry, el) {
    const group = el[key];

    if (carry[group] === undefined) {
      carry[group] = [];
    }

    carry[group].push(el);
    return carry;
  }, {});

  return groupedList;
};

const timeForToday = (time, detailDiff = true) => {
  if (!detailDiff) {
    const timeDiffDuration = dayjs.duration(dayjs().diff(time));
    const dayDiff = parseInt(timeDiffDuration.format('D'));
    if (dayDiff == 0) {
      return '오늘';
    }
  }
  return dayjs(time).fromNow();
};

const dateToString = (date, format) => dayjs(date).format(format);

const getWeekNumOfYear = () => dayjs().week();

const getYear = () => dayjs().year();

const addRefParam = (link) => {
  const url = new URL(link);
  const params = new URLSearchParams(url.search);
  params.append('ref', 'codenary');
  return `${url.origin}${url.pathname}?${params.toString()}`;
};

const validateUsername = (username) => {
  if (!username) return false;

  const re = new RegExp('^[a-zA-Zㄱ-힣0-9]*$');
  return username.length < 12 && re.test(username);
};

const convertCamelObjectToSnake = (obj) => {
  const snakeConvertedObj = {};
  for (const [k, v] of Object.entries(obj)) {
    snakeConvertedObj[snakeCase(k)] = v;
  }
  return snakeConvertedObj;
};

function useReplaceQueryParams() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const queryParams = searchParams as ReadonlyURLSearchParams;
  const urlSearchParams = new URLSearchParams(searchParams + '');

  const setQueryParams = (params) => {
    window.history.pushState({}, '', `${pathname}?${params}`);
  };

  return { queryParams, urlSearchParams, setQueryParams };
}

const equalsIgnoreOrder = (a, b) => {
  if (a.length !== b.length) return false;

  const uniqueValues = new Set([...a, ...b]);

  for (const v of uniqueValues) {
    const aCount = a.filter((e) => e === v).length;
    const bCount = b.filter((e) => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};

const redirectToOneIdPage = () => {
  setRedirectUrlToCookie();
  const redirect = encodeURIComponent(getCookieItem(COOKIE_ITEMS.redirect));
  window.location.href = `${
    process.env.NEXT_PUBLIC_WANTED_ONEID
  }/login?client_id=${process.env.NEXT_PUBLIC_WANTED_CLIENTID}&before_url=${
    process.env.NEXT_PUBLIC_BASE_URL +
    (redirect === 'undefined' ? '/' : redirect)
  }&redirect_url=${
    process.env.NEXT_PUBLIC_AUTH_ENDPOINT
  }/login/wanted&message_key=codenary_default`;
};

const ucFirst = (str) => {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1);
};

const emojiSource = (emoji: string, type: 'twitter' | 'apple' | 'google') => {
  const src = {
    twitter:
      'https://cdn.jsdelivr.net/npm/emoji-datasource-twitter/img/twitter/64/',
    apple: 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/',
    google:
      'https://cdn.jsdelivr.net/npm/emoji-datasource-google/img/google/64/',
  };

  return `${src[type]}${emoji}.png`;
};

const isDark = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  const dark = colorScheme === 'dark';

  return { dark, toggleColorScheme };
};

export {
  sleep,
  groupBy,
  timeForToday,
  dateToString,
  getWeekNumOfYear,
  getYear,
  addRefParam,
  validateUsername,
  convertCamelObjectToSnake,
  useReplaceQueryParams,
  equalsIgnoreOrder,
  redirectToOneIdPage,
  ucFirst,
  emojiSource,
  isDark,
};
